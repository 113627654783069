import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'static/css/Input.css';

export default class Input extends Component {
    static propTypes = {
        onZipUpdate: PropTypes.func,
        onCategoryUpdate: PropTypes.func
    };

    onZipSubmit(event) {
        this.props.onZipUpdate(this.refs.zip.value);
    }

    onCategoryUpdate(event) {
        this.props.onCategoryUpdate(event.target.value);
    }

    render() {
        return (
            <div className='input'>
                <div className='cover'>
                    <div className='category'>
                        <span>mediocre</span>
                        <select onChange={this.onCategoryUpdate.bind(this)}>
                            <option value='restaurant'>restaurants</option>
                            <option value='cafe'>cafes</option>
                            <option value='bar'>bars</option>
                            <option value='doctor'>doctors</option>
                            <option value='night_club'>night clubs</option>
                            <option value='church'>churches</option>
                            <option value='lawyer'>lawyers</option>
                            <option value='gas_station'>gas stations</option>
                        </select>
                    </div>
                    <div className='zip'>
                        <span>near</span>
                        <input type='text' placeholder='5 digit zip' ref='zip' onKeyUp={event => {if (event.key === 'Enter') this.onZipSubmit();}} />
                    </div>
                    <div className='button' onClick={this.onZipSubmit.bind(this)}>get underwhelmed</div>
                </div>
            </div>
        );
    }
}
