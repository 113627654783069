import React, { Component } from 'react';

import ActivityIndicator from 'components/ActivityIndicator';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Input from 'components/Input';

import 'static/css/App.css';

import zipcodes from 'static/util/zipcodes';
import averageImage from 'static/images/avg.jpg';
import mehImage from 'static/images/meh.svg';
import mediIcon from 'static/images/medi.svg';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zip: null,
            category: 'restaurant',
            data: [],
            isBusy: false
        };
    }

    loadData() {
        const {zip, category} = this.state;
        const location = zipcodes[zip];
        if (!location) return;
        this.setState({isBusy: true, data: []}, () => {
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            let data = [];
            service.nearbySearch({
                location: {lat: location.Lat, lng: location.Long},
                type: category,
                rankBy: window.google.maps.places.RankBy.DISTANCE
            }, (results, status, pagination) => {
                data = data.concat(results);
                if (pagination.hasNextPage) {
                    pagination.nextPage();
                } else {
                    data.sort((a, b) => {
                        const deltaA = Math.abs(3.2 - (a.rating || -1000));
                        const deltaB = Math.abs(3.2 - (b.rating || -1000));
                        return deltaA - deltaB;
                    });
                    this.setState({data, isBusy: false});
                }
            });
        });
    }

    onZipUpdate(zip) {
        this.setState({zip}, this.loadData.bind(this));
    }

    onCategoryUpdate(category) {
        this.setState({category});
    }

    renderResults() {
        const {data} = this.state;
        return data.map((result, index) => (
            <a target='_blank' rel='noopener noreferrer' href={'https://maps.google.com/?q=' + result.name + '&ll=' + result.geometry.location.lat() + ',' + result.geometry.location.lng()} key={index} className='result'>
                <div className='thumbnail' style={{backgroundImage: 'url(' + (result.photos ? result.photos[0].getUrl() : averageImage) + ')'}}>
                    <div className='gradient' />
                    <div className='medis'>
                        {result.rating && result.rating >= 1.2 && result.rating <= 4.8 && <img src={mediIcon} alt='medi' />}
                        {result.rating && result.rating >= 2.0 && result.rating <= 4.0 && <img src={mediIcon} alt='medi' />}
                        {result.rating && result.rating >= 2.5 && result.rating <= 3.5 && <img src={mediIcon} alt='medi' />}
                    </div>
                </div>
                <div className='cardContent'>
                    <div className='name'>{result.name}</div>
                    <div className='vicinity'>{result.vicinity}</div>
                    <div className='rating'>{!result.rating ? 'suspiciously unrated' : (result.rating >= 2.5 && result.rating <= 3.5 ? 'certified mediocre' : (result.rating >= 2.0 && result.rating <= 4.0 ? 'pretty mediocre' : 'too good or too gross'))}</div>
                </div>
            </a>
        ));
    }

    render() {
        const {isBusy, data} = this.state;

        let manifesto;
        if (!isBusy && data.length === 0) {
            manifesto = (
                <div className='manifesto'>
                    <img src={mehImage} alt='meh' />
                    <div>
                        <h3>Inject mediocrity into your life.</h3>
                        <div className='statement'>Our public profiles try to mimic Kardashian lifestyles to keep up with our friends’ similarly fraudulent facades.</div>
                        <div className='statement'>We strive to live the most unique experiences only to find that we’re following the same path as people before us.</div>
                        <div className='statement'>Our passions and talents are something to be tapped, to be harnessed. However, few of us are exceptional. The remarkable sacrifice their lifestyle, identity, and values to reach the pinnacle of their craft.</div>
                        <div className='statement'>We are perennially unsatisfied and require radical intervention. For this reason, we are intentionally seeking opportunities to reset our expectation to live a more fulfilling life.</div>
                        <div className='statement'>The world needs mediocre.business</div>
                    </div>
                </div>
            );
        }

        let results;
        if (data.length > 0) {
            results = (
                <div className='results'>
                    {this.renderResults()}
                </div>
            );
        }

        return (
            <div className='app'>
                <Header />
                <Input onZipUpdate={this.onZipUpdate.bind(this)} onCategoryUpdate={this.onCategoryUpdate.bind(this)} />
                <div className='busyIndicator'>
                    <ActivityIndicator isBusy={isBusy} />
                </div>
                {manifesto}
                {results}
                <Footer />
            </div>
        );
    }
}
