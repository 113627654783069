import React, { Component } from 'react';

import 'static/css/Header.css';

export default class Header extends Component {
    render() {
        return (
            <div className='header'>
                mediocre.business
            </div>
        );
    }
}
