import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'static/css/ActivityIndicator.css';

export default class ActivityIndicator extends Component {
    static propTypes = {
        isBusy: PropTypes.bool // when isBusy it will spin
    };

    static defaultProps = {
        isBusy: true
    };

    render() {
        const {isBusy} = this.props;
        if (!isBusy) return <div />;
        return (
            <div className='activityIndicator'>
                <div className='ball ball1' />
                <div className='ball ball2' />
                <div className='ball ball3' />
            </div>
        );
    }
}
