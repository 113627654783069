import React, { Component } from 'react';

import 'static/css/Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                &copy; 2018 <a href='mailto:swordfishwestco@gmail.com'>Swordfish and West Co.</a> LLC
            </div>
        );
    }
}
